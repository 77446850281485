import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "@/templates/layout"

import { useTranslation } from "react-i18next";

// @ts-ignore
import countries from "../../wp/coins/countries.js"

interface SitemapLink {
  label: string
  url: string
}

interface SitemapGroup extends SitemapLink {
  items: SitemapLink[]
}

const Sitemap = (props: {
  exchanges: { title: string, slug: string }[],
  coins: { title: string, slug: string }[],
}
) => {
  const { t } = useTranslation()
  const { exchanges, coins } = props
  const data: SitemapGroup[] = []

  data.push({
    label: "Exchanges",
    url: "/exchanges/",
    items: exchanges.map((node) => ({
      label: node.title,
      url: `/exchanges/${node.slug}/`,
    }))
  })

  const c = (Object.entries(countries) as [string, string][])
  for (const coin of coins) {
    data.push({
      label: coin.title,
      url: `/coins/${coin.slug}/`,
      items: c.map(([label, slug]) => ({
        label: t('where-to-buy', { coin: coin.title, country: label }),
        url: `/coins/${coin.slug}/${slug}/`,
      })),
    })
  }

  return <div className="sitemap">
    <h3>{t('sitemap')}</h3>
    {data.map((group) => <div className="sitemap-group">
      <header>
        <Link to={group.url}>{group.label}</Link>
      </header>
      <div className="sitemap-items">
        {group.items.map((item) =>
          <Link to={item.url}>{item.label}</Link>
        )}
      </div>
    </div>)}
  </div>
}

const Page = (props: any) => {
  const { t } = useTranslation()
  const { exchanges, coins } = props.data
  const seo = {
    title: t('seo_title'),
    description: t('seo_description')
  }

  return <Layout {...props} seo={seo} className="page-sitemap" title="MadCrypto">
    <Sitemap exchanges={exchanges.nodes} coins={coins.nodes} />
  </Layout>
}

export default Page

export const query = graphql`
  query ($language: String!) {
    exchanges: allWpSite(filter: {locale: { locale: {eq: $language }}}) {
      nodes {
        title
        slug
      }    
    }
    coins: allWpCoin(filter: {locale: { locale: {eq: $language }}}) {
      nodes {
        title
        slug
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;